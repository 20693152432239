import React from 'react';
import './Navbar.css';
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import Link from "../Link/Link";
import Logo from "../../assets/logos/msflogostandard.png";
import {useLanguageValue} from "../../LanguageContext";

const Navbar = ({activePage, handleActivePage}) => {

  const [{ lang,t }] = useLanguageValue();

  return (
    <div className="navbar">
      <HamburgerMenu handleActivePage={handleActivePage}/>
      <div className="navbar-links">
        <div
          className={activePage === '/' || activePage === '/home' ? 'active-page' : ''}
          onClick={() => {
            handleActivePage('/')
          }}><Link to={`${process.env.PUBLIC_URL}/`}>{t['links']['home'][lang]}</Link>
        </div>
        <div
          className={activePage === '/about-us' ? 'active-page' : ''}
          onClick={() => {
            handleActivePage('/about-us')
          }}><Link to={`${process.env.PUBLIC_URL}/about-us`}>{t['links']['about'][lang]}</Link>
        </div>
      </div>
      <div className="logo-container">
        <a href="http://www.msfequestrian.se/">
          <img src={Logo} className="navbar-logo" alt="logo"/>
        </a>
      </div>
      <div className="navbar-links">
        <div
          className={activePage === '/our-horses' ? 'active-page' : ''}
          onClick={() => {
            handleActivePage('/our-horses')
          }}><Link to={`${process.env.PUBLIC_URL}/our-horses`}>{t['links']['horses'][lang]}</Link>
        </div>
        <div
          className={activePage === '/contact-us' ? 'active-page' : ''}
          onClick={() => {
            handleActivePage('/contact-us')
          }}><Link to={`${process.env.PUBLIC_URL}/contact-us`}>{t['links']['contact'][lang]}</Link>
        </div>
      </div>
    </div>
  )
}

export default Navbar;

import React, {useEffect, useState} from 'react';
import './Home.css';
import HeroImage from "../../assets/landing-page-photo1.jpg";
import HeroImageMobile from "../../assets/landing-page-photo-mobile.jpg";
import Marre from "../../assets/framsidabild.png";
import {useLanguageValue} from "../../LanguageContext";
import Quote from "../Quote/Quote";
const Home = () => {

  const [{ lang,t }] = useLanguageValue();

  const [urls, setUrls] = useState([
    {
      media_url: "",
      permalink: ""
    },
    {
      media_url: "",
      permalink: ""
    },
    {
      media_url: "",
      permalink: ""
    },
    {
      media_url: "",
      permalink: ""
    }
  ]);
  const [urls2, setUrls2] = useState([
    {
      media_url: "",
      permalink: ""
    },
    {
      media_url: "",
      permalink: ""
    },
    {
      media_url: "",
      permalink: ""
    },
    {
      media_url: "",
      permalink: ""
    }
  ]);
  const [ids, setIds] = useState([]);


  //Fetch latest id's for Instagramposts
  useEffect(() => {
    let arrayIds = [];
    let cursorNext = '';

    fetch('https://graph.instagram.com/me/media?fields=id,caption,media_type&access_token=IGQVJVOGR2QUtuWkFDb19hd2RpNXlOS185V25WczZAaUno5OG9xd25QdG5pQzdBVE9UYlFqODktREVsWjBRSXhJNnkzUjByOWtmeHRTMTA4dWRhd2pWNDhHcE1nVUppMThuMTVvMWc2YmJxN0ZAPbC0ybgZDZD')
      .then(response => response.json())
      .then(data => {
        if(data.data !== undefined && data !== undefined)
          arrayIds = data.data.filter((item) => item.media_type == 'IMAGE').slice(0, 8);
          cursorNext = data.paging.next;
      })
      .then(() => {
        // Instagram only gets 25 latest posts - If we dont have 8 images in these 25 posts, fetch next 25 posts.
        if(arrayIds.length < 8){
          fetch(cursorNext)
          .then(response => response.json())
          .then(data => {
            if(data.data !== undefined && data !== undefined){
              let moreIds = data.data.filter((item) => item.media_type == 'IMAGE').slice(0, (8 - arrayIds.length));
              moreIds.forEach((x) => {
                arrayIds.push(x);
              })
            }
          })
          .then(() => {
            setIds(arrayIds);
          });
        } else {
          setIds(arrayIds);
        }
      });

    
  }, [])

  // Fetch MediaURL for 8 images
  useEffect(() => {
    let mediaUrls = [];
    ids.forEach((x) => {
      fetch(`https://graph.instagram.com/${x.id}?fields=id,permalink,media_type,media_url,username,timestamp&access_token=IGQVJVOGR2QUtuWkFDb19hd2RpNXlOS185V25WczZAaUno5OG9xd25QdG5pQzdBVE9UYlFqODktREVsWjBRSXhJNnkzUjByOWtmeHRTMTA4dWRhd2pWNDhHcE1nVUppMThuMTVvMWc2YmJxN0ZAPbC0ybgZDZD`)
        .then(response => response.json())
        .then(data => {
          if(data !== undefined){
            mediaUrls.push(data);
            if (mediaUrls.length === ids.length) {
              setUrls(mediaUrls.slice(0,4));
              setUrls2(mediaUrls.slice(4, 8));
            }
          }
        });
    })
  }, [ids]);


  return (
    <div>
      <div className="hero-image" style={{backgroundImage: 'url(' + HeroImage + ')'}}>

      </div>
      <div className="hero-image-mobile" style={{backgroundImage: 'url(' + HeroImageMobile + ')'}}>

      </div>
      <div className="container">
        <h1 className="home-header">{t['home']['header'][lang]}</h1>
        <div className="home-container">
          <div className="home-text-container-mobile">
            <div>{t['home']['welcomeText1'][lang]}</div>
            <div style={{marginTop: 20}}>{t['home']['welcomeText2'][lang]}</div>
          </div>
          <div className="home-picture-container">
            <img src={Marre} alt="Marielle Eriksson" style={{width: '100%'}}/>
          </div>
          <div className="home-text-container-mobile">
            <div style={{marginTop:25}}>{t['home']['marreText1'][lang]}</div>
            <div style={{marginTop:15}}>{t['home']['marreText2'][lang]}</div>
            <Quote quoteFrom={t['home']['quoteFrom1'][lang]} quoteText={t['home']['quoteText1'][lang]}/>
          </div>
          <div className="home-text-container">
            <div className="home-text-1">
              {t['home']['welcomeText1'][lang]}
              {t['home']['welcomeText2'][lang]}

            </div>
            <div className="home-text-2">
              {t['home']['marreText1'][lang]}
              {t['home']['marreText2'][lang]}
              <Quote quoteFrom={t['home']['quoteFrom1'][lang]} quoteText={t['home']['quoteText1'][lang]}/>
            </div>
          </div>
        </div>
        <h1 className="instagram-header">Instagram @MSFEquestrian</h1>
        <div className="instagram-container">
          <div className="instagram-card-container">
            {
              urls.map((x) => {
                return (
                    <div className="instagram-card" style={{backgroundImage: `url(${x.media_url})`, position:"relative"}}>
                      <a  aria-hidden="true" href={x.permalink} rel="noopener noreferrer" target="_blank" style={{width: '100%', height: '100%', position: 'absolute', left: '0px'}}></a>
                    </div>
                );
              })
            }
            <div className="instagram-card-container">
            {
              urls2.map((x) => {
                return (
                  <div className="instagram-card" style={{backgroundImage: `url(${x.media_url})`, position:"relative"}}>
                    <a href={x.permalink} rel="noopener noreferrer" target="_blank" style={{width: '100%', height: '100%', position: 'absolute', left: '0px'}}></a>
                  </div>
                );
              })
            }
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home;
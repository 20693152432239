import React from 'react';
import './Horses.css';
import HeroImage from "../../assets/landing-page-photo1.jpg";
import Nasse from "../../assets/horses/nasseomhast.png";
import Agata from "../../assets/horses/agata.jpg";
import Olle from "../../assets/horses/olleomhast.png";
import MdFemale from "react-ionicons/lib/MdFemale";
import MdMale from "react-ionicons/lib/MdMale";
import JumpingHorse from "../../assets/771A2437.jpg";
import { useLanguageValue } from "../../LanguageContext";



const Horses = () => {
  const [{ lang, t }] = useLanguageValue();
  const OwnedHorses = [{
    name: 'Quito D’Amour Z',
    born: '2015',
    owner: 'Marielle Eriksson',
    father: 'Quillan V Z',
    mother: 'Cameron V',
    farfar: 'Quintender 2',
    farmor: 'Gloria',
    morfar: 'Cambridge',
    mormor: 'Mona Meloy',
    img: Nasse
  },
  {
    name: 'Agata',
    born: '2018',
    owner: 'Phillip Oram',
    father: 'Casanova Hästak',
    mother: 'Beata',
    farfar: 'Cosmeo',
    farmor: 'Diana',
    morfar: 'Majim G',
    mormor: 'Jennie II',
    img: Agata
  },
  {
    name: 'Otello - ' + t['horses']['sold'][lang],
    born: '2014',
    owner: 'Phillip Oram',
    father: 'Krakus',
    mother: 'Olza',
    farfar: 'Farnik',
    farmor: 'Karmen',
    morfar: 'Hamlet Go',
    mormor: 'Olbia',
    img: Olle
  }]

  

  return (
    <div>
      <div className="hero-image" style={{ backgroundImage: 'url(' + HeroImage + ')' }}>

      </div>
      <div className="horses-hero-image">
        <img src={JumpingHorse} alt="Jumping Horse" style={{ width: '100%', height: 'auto' }} />
      </div>
      <div className="container">
        <h1 className="horses-header">{t['horses']['header'][lang]}</h1>
        <div className="horses-container">
          {OwnedHorses.map(x => {
            return (
              <div className="horse-card">
                <div className="horse-picture">
                  <img src={x.img} style={{ width: '100%', objectFit: 'cover' }} alt="nasse" />
                </div>
                <div className="horse-text-container">
                  <h2>{x.name}</h2>
                  <div className="horse-text1">
                    {t['horses']['born'][lang]} {x.born}
                  </div>
                  <div className="horse-text2">
                    {t['horses']['owner'][lang]} {x.owner}
                  </div>
                  <div className="stamtavla">
                    <h3 style={{ color: 'white', backgroundColor: '#553D67', width: '33%', padding: 10, margin: 0 }}>{t['horses']['pedigree'][lang]}</h3>
                    <div className="stamtavla-contents">
                      <div className="hasten">
                        <MdMale color="#553D67" />
                        <p>{x.name}</p>
                      </div>
                      <div className="parents">
                        <div className="far">
                          <MdMale color="#553D67" />
                          <p>{x.father}</p>
                        </div>
                        <div className="mor">
                          <MdFemale color="#553D67" />
                          <p>{x.mother}</p>
                        </div>
                      </div>
                      <div className="grandparents">
                        <div className="grand-far">
                          <div className="farfar">
                            <MdMale color="#553D67" />
                            <p>{x.farfar}</p>
                          </div>
                          <div className="farmor">
                            <MdFemale color="#553D67" />
                            <p>{x.farmor}</p>
                          </div>
                        </div>
                        <div className="grand-mor">
                          <div className="morfar">
                            <MdMale color="#553D67" />
                            <p>{x.morfar}</p>
                          </div>
                          <div className="mormor">
                            <MdFemale color="#553D67" />
                            <p>{x.mormor}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>)
}

export default Horses;
import React from 'react';
import './Footer.css';
import FooterLogo from '../../assets/logos/msflogowhite.png';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-text">
          <div style={{padding: 5}}>marielle.eriksson@outlook.com</div>
          <div style={{padding: 5}}>+46 (0)76 097 36 80</div>
        </div>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <a href="http://www.msfequestrian.com/">
            <img src={FooterLogo} className="footer-logo" alt="footer-logo"/>
          </a>
        </div>
        <div className="footer-social-container">
          <a href="https://www.facebook.com/msf.equestrian/" target="_blank" rel="noopener noreferrer">
            <svg width="21" height="41" viewBox="0 0 21 41" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className="footer-facebook">
              <path
                d="M5.25 13.6667H0V20.5H5.25V41H14V20.5H20.3735L21 13.6667H14V10.8189C14 9.18742 14.336 8.54167 15.9513 8.54167H21V0H14.336C8.043 0 5.25 2.70429 5.25 7.88396V13.6667Z"
                fill="#FFFAFA"/>
            </svg>
          </a>
          <a href="http://instagram.com/msf.equestrian" target="_blank" rel="noopener noreferrer">
            <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className="footer-instagram">
              <path
                d="M22.5 4.05562C28.5075 4.05562 29.22 4.07812 31.5938 4.18687C37.6912 4.46438 40.5394 7.3575 40.8169 13.41C40.9256 15.7819 40.9462 16.4944 40.9462 22.5019C40.9462 28.5113 40.9237 29.2219 40.8169 31.5938C40.5375 37.6406 37.6969 40.5394 31.5938 40.8169C29.22 40.9256 28.5112 40.9481 22.5 40.9481C16.4925 40.9481 15.78 40.9256 13.4081 40.8169C7.29563 40.5375 4.4625 37.6313 4.185 31.5919C4.07625 29.22 4.05375 28.5094 4.05375 22.5C4.05375 16.4925 4.07812 15.7819 4.185 13.4081C4.46438 7.3575 7.305 4.4625 13.4081 4.185C15.7819 4.07812 16.4925 4.05562 22.5 4.05562ZM22.5 0C16.3894 0 15.6244 0.02625 13.2244 0.135C5.05313 0.51 0.511875 5.04375 0.136875 13.2225C0.02625 15.6244 0 16.3894 0 22.5C0 28.6106 0.02625 29.3775 0.135 31.7775C0.51 39.9487 5.04375 44.49 13.2225 44.865C15.6244 44.9737 16.3894 45 22.5 45C28.6106 45 29.3775 44.9737 31.7775 44.865C39.9412 44.49 44.4938 39.9562 44.8631 31.7775C44.9738 29.3775 45 28.6106 45 22.5C45 16.3894 44.9737 15.6244 44.865 13.2244C44.4975 5.06062 39.9581 0.511875 31.7794 0.136875C29.3775 0.02625 28.6106 0 22.5 0ZM22.5 10.9462C16.1194 10.9462 10.9462 16.1194 10.9462 22.5C10.9462 28.8806 16.1194 34.0556 22.5 34.0556C28.8806 34.0556 34.0538 28.8825 34.0538 22.5C34.0538 16.1194 28.8806 10.9462 22.5 10.9462ZM22.5 30C18.3581 30 15 26.6437 15 22.5C15 18.3581 18.3581 15 22.5 15C26.6419 15 30 18.3581 30 22.5C30 26.6437 26.6419 30 22.5 30ZM34.5112 7.79063C33.0187 7.79063 31.8094 9 31.8094 10.4906C31.8094 11.9812 33.0187 13.1906 34.5112 13.1906C36.0019 13.1906 37.2094 11.9812 37.2094 10.4906C37.2094 9 36.0019 7.79063 34.5112 7.79063Z"
                fill="#FFFAFA"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer;

import React, {useContext, useReducer} from 'react';

export const LanguageContext = React.createContext();

export const LanguageProvider = ({reducer, initialState, children}) =>(
  <LanguageContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </LanguageContext.Provider>
);

export const useLanguageValue = () => useContext(LanguageContext);

export default LanguageContext;

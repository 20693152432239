import React, {useState, useRef} from "react";
import PropTypes from "prop-types";
import {useOnClickOutside} from '../CustomHooks';
import './HamburgerMenu.css';
import Link from "../Link/Link";
import {useLanguageValue} from "../../LanguageContext";

function HamburgerMenu({activePage, handleActivePage}) {

  const [{ lang,t }] = useLanguageValue();

  const [isOpen, setIsOpen] = useState(false);

  function setMenuOpen(open) {
    const el = document.querySelector('#side-menu');

    if (open) {
      el.classList.remove('slide-out');
      el.classList.add('fadein');
      el.classList.add('appear');
      setIsOpen(true);
    } else {
      el.classList.remove('fadein');
      el.classList.remove('appear');
      el.classList.add("slide-out");
      setIsOpen(false);
    }
  }

  const ref = useRef();
  useOnClickOutside(ref, () => {if(isOpen)setMenuOpen(false)});

  return (
    <div ref={ref}>
      <div id="side-menu" className='side-menu'>
        <ul className="side-menu-container">
          <li
            className={activePage === '/' || activePage === '/home' ? 'active-page' : ''}
            onClick={() => {
              setMenuOpen(false);
              handleActivePage('/')
            }}><Link to={`${process.env.PUBLIC_URL}/`}>{t['links']['home'][lang]}</Link>
          </li>
          <li
            className={activePage === '/about-us' ? 'active-page' : ''}
            onClick={() => {
              setMenuOpen(false);
              handleActivePage('/about-us')
            }}><Link to={`${process.env.PUBLIC_URL}/about-us`}>{t['links']['about'][lang]}</Link>
          </li>
          <li
            className={activePage === '/our-horses' ? 'active-page' : ''}
            onClick={() => {
              setMenuOpen(false);
              handleActivePage('/our-horses')
            }}><Link to={`${process.env.PUBLIC_URL}/our-horses`}>{t['links']['horses'][lang]}</Link>
          </li>
          <li
            className={activePage === '/contact-us' ? 'active-page' : ''}
            onClick={() => {
              setMenuOpen(false);
              handleActivePage('/contact-us')
            }}><Link to={`${process.env.PUBLIC_URL}/contact-us`}>{t['links']['contact'][lang]}</Link>
          </li>
        </ul>
      </div>
      <div onClick={() => setMenuOpen(isOpen ? false : true)} className="hamburger-menu-wrapper">
        <svg  className="hamburger-menu" width="40" height="25" viewBox="0 0 40 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="3" fill="#553D67"/>
          <rect y="11" width="40" height="3" fill="#553D67"/>
          <rect y="22" width="40" height="3" fill="#553D67"/>
        </svg>
      </div>
    </div>
  );
}

HamburgerMenu.propTypes = {
  display: PropTypes.string,
  slide: PropTypes.string
};

export default HamburgerMenu;

import React, {useState, useEffect} from 'react';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Home from "./Components/Home/Home";
import Footer from "./Components/Footer/Footer";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import About from "./Components/About/About";
import Horses from "./Components/Horses/Horses";
import Contact from "./Components/Contact/Contact";
import translations from "./translations";
import {LanguageProvider, useLanguageValue} from "./LanguageContext";

function App() {
  const [activePage, setActivePage] = useState("/");

  const initialState = {
    lang: window.location.pathname.includes('com') ? 'en' : 'sv',
    t: translations
  };


  useEffect(() => {
      setActivePage("/" + window.location.pathname.split("/")[1])
  },[])

  const handleActivePage = (page) => {
    setActivePage(page);
  }



  const reducer = (state, action) => {
    switch (action.type) {
      case "toggleLanguage":
        return {
          ...state,
          lang: state.lang === "sv" ? "en" : "sv"
        };
      case "setLanguage":
        return {
          ...state,
          lang: action.value
        };
      default:
        return state;
    }
  };

  return (
      <LanguageProvider initialState={initialState} reducer={reducer}>
      <Router>
        <Navbar handleActivePage={handleActivePage} activePage={activePage} />
        <Switch>
          <Route exact path={["/", "/com"]}>
            <Home />
          </Route>
          <Route path={`/about-us`}>
            <About/>
          </Route>
          <Route path={`/our-horses`}>
            <Horses/>
          </Route>
          <Route path={`/contact-us`}>
            <Contact/>
          </Route>
        </Switch>
        <Footer/>
      </Router>
    </LanguageProvider>
  );
}

export default App;

import React from 'react';
import './Quote.css';

const Quote = ({quoteText, quoteFrom}) => {
  return (
    <div>
      <br/>
      <span className="quote-text">
        <span className="quote">"</span>
        {quoteText}
        <span className="quote">"</span>
      </span>
      <div className="quote-from">
        {quoteFrom}
      </div>


  </div>)

}

export default Quote;
import React, { useEffect } from "react";
import ReactGA from "react-ga";

const debug = window.location.hostname === 'localhost';

if (!debug) {
  const trackingId = "UA-149119801-1";
  ReactGA.initialize(trackingId);
}

const withTracker = (WrappedComponent, options = {}) => {

  const trackPage = page => {
    if (!debug) {
      ReactGA.set({
        page,
        ...options
      });

      ReactGA.pageview(page);
    } else {
      console.log('Debug: Would send page view: ', page)
    }
  };

  const HOC = props => {
    useEffect(() => trackPage(props.location.pathname), [
      props.location.pathname
    ]);
    return <WrappedComponent {...props} />;
  }

  return HOC;
};

export default withTracker;

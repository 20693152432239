import React from 'react';
import './Contact.css';
import HeroImage from "../../assets/landing-page-photo1.jpg";
import ContactImg from "../../assets/kontaktaossmobil.png";
import {useLanguageValue} from "../../LanguageContext";

const Contact = () => {

  const [{ lang,t }] = useLanguageValue();

  return (
    <div>
      <div className="hero-image" style={{backgroundImage: 'url(' + HeroImage + ')'}}>

      </div>
      <div className="contact-hero-image-mobile">
        <img src={ContactImg} style={{width: '100%', height: 'auto'}} alt="Contact" />
      </div>
      <div className="container">
        <h1 className="contact-header">{t['contact']['header'][lang]}</h1>
        <div className="contact-container">
          <div className="contact-text-container">
            {t['contact']['phoneText1'][lang]}
            <a href="mailto: marielle.eriksson@outlook.com">marielle.eriksson@outlook.com</a>
            {t['contact']['phoneText2'][lang]}
            <div className="contact-instagram-container">
              <h2 className="contact-instagram">Instagram</h2>
              <a href="http://www.instagram.com/msf.equestrian">@msf.equestrian</a>
            </div>
            <div className="contact-facebook-container">
              <h2 className="contact-facebook">Facebook</h2>
              <a href="http://www.facebook.com/msf.equestrian" target="_blank" rel="noopener noreferrer">MSF Equestrian</a>
            </div>
          </div>
          <div className="contact-find-us-mobile">
            <h1 style={{marginTop: 30}}>{t['contact']['findUs'][lang]}</h1>
            <div style={{marginTop: 20}}>
              {t['contact']['mapText'][lang]}<br/>
            </div>
            <div className="contact-map-container-mobile">
              <iframe title="map-mobile" width="100%" height="100%" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDfSzF-lcS7XzuBMrXW8S8spYlmsuP4GJk&q=varms%C3%A4tra%20181&"></iframe>
            </div>
            <div style={{width: '50%', marginTop: 20}}>
              Marielle Eriksson
              Varmsätra Per-Anders 180
              733 92 Sala
            </div>
          </div>
          <div className="contact-find-us-desktop">
            <div className="contact-map-container-desktop">
              <iframe title="map-desktop" width="100%" height="100%" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDfSzF-lcS7XzuBMrXW8S8spYlmsuP4GJk&q=varms%C3%A4tra%20181&"></iframe>
            </div>
            <div style={{textAlign: 'left'}}>
              <div style={{fontSize: 14, marginTop: 5}}>
                {t['contact']['mapText'][lang]}
              </div>
              <h2 style={{fontSize: 28, marginTop: 15}}>Marielle Eriksson</h2>
              <div>Varmsätra Per-Anders 180</div>
              <div>733 92 Sala</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Contact;
import React from 'react';
import './About.css';
import {ReactComponent as About1} from "../../assets/svg/about-1.svg";
import {ReactComponent as About2} from "../../assets/svg/about-2.svg";
import {ReactComponent as About3} from "../../assets/svg/about-3.svg";
import {ReactComponent as About4} from "../../assets/svg/about-4.svg";
import HeroImage from "../../assets/landing-page-photo1.jpg"
import PerAnders from "../../assets/per-anders.jpg";
import Marre from "../../assets/marre.png";
import Phillip from "../../assets/phill.png";
import {useLanguageValue} from "../../LanguageContext";
const About = () => {

  const [{ lang,t }] = useLanguageValue();

  return (
    <div>
      <div className="hero-image" style={{backgroundImage: 'url(' + HeroImage + ')'}}>

      </div>
      <div className="about-hero-image-mobile">
        <img style={{width: '100%', height: 'auto'}} src={PerAnders} alt="per-anders gård"/>
      </div>
      <div className="container">
        <h1 className="about-header">{t['about']['header'][lang]}</h1>
        <div className="about-text-container-mobile">
          {t['about']['perAnders1'][lang]}
        </div>
        <div className="about-container">
          <div className="about-picture-container-desktop">
            <img style={{width: '100%', height: 'auto'}} src={PerAnders} alt="per-anders gård"/>
          </div>
          <div style={{display: 'block', width:'50%', marginLeft: '30px'}}>
            <div className="about-text-container-desktop">
              {t['about']['perAnders1'][lang]}
            </div>
            <div className="about-text-container-desktop">
              {t['about']['perAnders2'][lang]}
            </div>
          </div>
        </div>
        <h1 className="services-header">{t['about']['services'][lang]}</h1>
        <div className="about-text-container-mobile">
          {t['about']['perAnders2'][lang]}
        </div>
        <div className="service-container">
          <div className="service-text-container-desktop">
            <div className="service-text">{t['about']['services1'][lang]}</div>
            <div className="service-text">{t['about']['services2'][lang]}</div>
            <div className="service-text">{t['about']['services3'][lang]}</div>
            <div className="service-text">{t['about']['services4'][lang]}</div>
          </div>
          <div className="service-about-container">
            <div className="service-text-container">
              <About1/>
              <span>Inridning, löshoppning och tömkörning</span>
            </div>
            <div className="service-text-container">
              <About2/>
              <span>Tävling och kval till årgångschampionat</span>
            </div>
            <div className="service-text-container">
              <About3/>
              <span>Utbildar och tränar både hästar och ponnys</span>
            </div>
            <div className="service-text-container">
              <About4/>
              <span>Träning av ekipage</span>
            </div>
          </div>

        </div>
        <h1 className="services-header" style={{marginTop: 30}}>{t['about']['teamMembers'][lang]}</h1>
        <div className="card-container">
          <div className="card">
            <div className="picture">
              <img src={Marre} alt="Marielle Eriksson" style={{width: '100%'}}/>
            </div>
            <div className="text-container">
              <h2>Marielle Eriksson</h2>
              <h3 className="text1">
                {t['about']['marreTitle'][lang]}
              </h3>
              <div className="text2">
                {t['about']['marreText'][lang]}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="picture">
              <img src={Phillip} alt="Phillip Oram" style={{width: '100%'}}/>
            </div>
            <div className="text-container">
              <h2>Phillip Oram</h2>
              <h3 className="text1">
                {t['about']['phillTitle'][lang]}
              </h3>
              <div className="text2">
                {t['about']['phillText'][lang]}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>)
}

export default About;
export default{
  links: {
    about: {
      sv: 'Om Oss',
      en: 'About us'
    },
    home: {
      sv: 'Hem',
      en: 'Home'
    },
    contact: {
      sv: 'Kontakt',
      en: 'Contact'
    },
    horses: {
      sv: 'Våra hästar',
      en: 'Our horses'
    }
  },
  home: {
    header: {
      sv: 'Välkommen till MSF Equestrian',
      en: 'Welcome to MSF Equestrian'
    },
    welcomeText1:{
      sv: 'Välkomna till MSF Equestrian. Vi arbetar med utbildning, inridning och försäljning av hästar på Per Anders gård som är belägen i Sala kommun.',
      en: 'Welcome to MSF Equestrian. We educate, ride and sell horses at Per Anders farm located in Sala municipality.'
    },
    welcomeText2:{
      sv:'Vi anser att alla hästar är värda en chans, därför vi tar emot unghästar så väl som problemhästar.',
      en:'We have a strong belief that all horses are worthy of a chance, therefore we accept both young horses as well as problem horses.'
    },
    marreText1: {
      sv: 'Vår ägare Marielle har 15 års erfarenhet inom hästträning och har jobbat som beridare runtom hela Europa samt Australien och Nya Zeeland. ',
      en: 'Our owner Marielle has 15 years experience in horse training and has worked as a rider around Europe as well as Australia and New Zealand.'
    },
    marreText2 : {
      sv: 'Välkommen att kontakta oss idag för att se vilken utbildning ni behöver för just er häst.',
      en: 'Welcome to contact us today to see what training you need for your horse.'
    },
    quoteText1: {
      sv: 'Marielle var kul att lära känna! Glad, trevlig med STOR hästkunskap! På den korta tid jag träffat henne så har hon fixat en häst med kraftigt sadeltvång till att börja hoppträna. En stor gänglig 5-åring som inte kunde hålla galopp knappt en långsida till att den nu både orkar och hoppar FANTASTISKT. Marielle är tålmodig, kunnig och känner mycket väl av hästen. Kan varmt rekommendera.',
      en: ''
    },
    quoteFrom1:{
      sv: 'Monica Johansson Rid&Hästsport B-hopptränare',
      en: ''
    }
  },
  about:{
    header: {
      sv: 'Om oss',
      en: 'About us'
    },
    perAnders1: {
      sv: 'Per Anders gård ligger Varmsätra, en mil utanför Sala, Västmanland. På gården finns ett tiotal olika byggnader inklusive ett mindre stall med tillgång till fem hektar mark.',
      en: 'Per Anders farm is located in Varmsätra, 10 km outside of Sala, Västmanland. The farm has about ten different buildings, including a smaller stable with access to five hectares of land.'
    },
    perAnders2: {
      sv: 'Vår målsättning är att visa vägen och utbilda hästar till deras fulla potential. Vid försäljning gör vi alltid vårt bästa för att matcha häst och ryttare.',
      en: 'Our goal is to show the way and train horses to their full potential. When selling, we always do our best to match horse and rider.'
    },
    services1: {
      sv: 'På Per Anders gård ligger hästarna i fokus. Vi utbildar varje enskild häst till deras fulla potential. Vi arbetar för en långsiktig och varaktig utbildning och tar emot såväl tävlingshästar som promenad- och hobbyhästar vars ryttare känner sig otrygga eller har kört fast i utvecklingen.',
      en: 'At Per Anders farm the horses are the main focal point. We train each individual horse to their full potential. We work for long lasting training and will accept both competition horses and hobby horses whose riders feel unsafe or have gotten stuck in development.'
    },
    services2: {
      sv: 'I utbildningen ingår ett varierande upplägg med bla tömkörning, löshoppning, lastträning, grundridning och uteritter.',
      en: 'The training includes a varied arrangement with tömkörning, loose jumping, lorry training, basic and outdoor rides.'
    },
    services3: {
      sv: 'Hos oss vistas hästarna utomhus varje dag i stora och fina hagar.',
      en: 'At our farm the horses stay outdoors every day in large and safe hagar.'
    },
    services4: {
      sv: 'För dig som vill utbilda och rida in din unghäst finns möjlighet att få hjälp och guidning till att utbilda din egna häst.',
      en: 'For those who want to break in and ride your young horse there is the opportunity to get help and guidance to train your own horse.'
    },
    marreTitle: {
      sv: 'Ägare och ryttare',
      en: 'Owner and rider'
    },
    phillTitle: {
      sv: 'Ägare och handyman',
      en: 'Owner and handyman'
    },
    marreText: {
      sv:'Marielle har tävlat med placeringar upp till 1.40m. Hon även ridit och arbetat utomlands i 6 år tillsammans med Morten Aasen, William Funnell, James Fisher och Xtreme sport horses där hon utbildade unghästar till elitnivå.',
      en: 'Marielle has competed with placements up to 1.40m. She also rode and worked abroad for 6 years with Morten Aasen, William Funnell, James Fisher and Xtreme sport horses where she trained young horses to elite level.'
    },
    phillText:{
      sv: 'Phillip kommer ursprungligen från Brighton, England och flyttade till Sverige med Marielle år 2017. Han ser till att allt runtomkring stallet fungerar som det ska.',
      en: 'Phillip is originally from Brighton, England and moved to Sweden with Marielle in 2017. He makes sure everything around the stables works properly.'
    },
    teamMembers: {
      sv: 'Vi på gården',
      en: 'Team members'
    },
    services: {
      sv: 'Våra tjänster',
      en: 'Our services'
    }
  },
  contact: {
    header: {
      sv: 'Kontakta oss',
      en: 'Contact us'
    },
    findUs: {
      sv: 'Hitta hit',
      en: 'Find us'
    },
    phoneText1: {
      sv: 'Vill du veta mer om oss och våra tjänster? Mejla oss på ',
      en: 'Do you want to know more about us and our services? Send us an email at '
    },
    phoneText2: {
      sv: 'eller slå en signal på 076-097 36 80 så berättar vi mer.',
      en: 'or give us a call at +46 (0)76 097 36 80.'
    },
    mapText: {
      sv: 'Per Anders gård ligger i Varmsätra, en mil utanför Sala. 30 minuter från Enköping och 1,5 timme från Stockholm City.',
      en: 'Per Anders farm is located in Varmsätra, 10 km outside Sala. 30 minutes drive from Enköping and 1.5 hours from Stockholm City.'
    }
  },
  horses: {
    header: {
      sv: 'Våra hästar',
      en: 'Our horses'
    },
    born: {
      sv: 'Födelseår',
      en: 'Born'
    },
    pedigree: {
      sv: 'Stamtavla',
      en: 'Pedigree'
    },
    owner: {
      sv: 'Ägare',
      en: 'Owner'
    },
    sold: {
      sv: 'Såld',
      en: 'Sold'
    }
  }
}
